import React, { useState, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import NavWithRouter from './NavigationBar';
import Jumbotron from './Jumbotron';
import { Container, Row, Col } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../Footer/Footer';
import { Descriptions } from 'antd';
import 'antd/dist/antd.css';

const query = gql`
  query allDealerRequest($dealerToken: String) {
    allDealerRequest(dealerToken: $dealerToken) {
        dealerId
        dealerName
        dEmail
        contactName1
        contactName2
        dealerAddress
        dealerAddress2
        dealerPhone
        dealerCity
        dealerState
        dealerZip
        registerDate
        achValidation
        routingNumber
        accountNumber
        achLimitReqd
        achLimit
        accountType
    }
  }
`;

const MyAccountDisplay = () => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [spinnerText, setSpinnerText] = useState('Loading your content...');
    const dealerToken = (localStorage.getItem('dealerToken')) ? localStorage.getItem('dealerToken') : '';
    let {loading, data, error} = useQuery(query, {
        variables: { dealerToken },
        fetchPolicy: "network-first"
    });
    const [displayFields, setDisplayFields] = useState(
        { dealerId: '',dealerName: '', dEmail: '', contactName1: '', contactName2: '', dealerAddress: '', dealerAddress2: '', dealerCity: '', dealerState: '', dealerZip: '', registerDate: '', 
        achValidation: '', routingNumber: '', accountNumber: '', achLimitReqd: '', achLimit: '', accountType: '', dealerPhone: ''
        }
      );
    useEffect(() => {
        //if (loading) console.log(loading);
        
        if (!loading && !error && spinnerDisplay) {
            
            console.log('data: ',data);

            let tempRowData = [];
            data.allDealerRequest.map(dealerEntry => {
                let dealer = {dealerId:dealerEntry.dealerId, dealerName:dealerEntry.dealerName, dEmail: dealerEntry.dEmail, contactName1: dealerEntry.contactName1,
                contactName2: dealerEntry.contactName2, dealerAddress: dealerEntry.dealerAddress, dealerAddress2: dealerEntry.dealerAddress2, dealerCity: dealerEntry.dealerCity,
                dealerState: dealerEntry.dealerState, dealerZip: dealerEntry.dealerZip, registerDate: dealerEntry.registerDate, achValidation: dealerEntry.achValidation,
                routingNumber: dealerEntry.routingNumber, accountNumber: dealerEntry.accountNumber, achLimitReqd: dealerEntry.achLimitReqd,
                achLimit: dealerEntry.achLimit, accountType: dealerEntry.accountType, dealerPhone: dealerEntry.dealerPhone
            }
                setDisplayFields(dealer);
                //tempRowData.push(dealer);
            });
            setSpinnerDisplay(false);
            
        }
    })

    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay}
                spinner
                text={spinnerText}
                >
                <NavWithRouter />
                <Jumbotron image="reporting" text="Dealer MyAccount"/>
                    <br />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <p>
                                If any banking updates are needed, visit the <a href='/contactus'>Contact Us</a> page and send in a request for SunPower to review. Please note any payments to be funded will be placed on a dispursement hold until the banking information has been successfully updated.
                                </p>
                                <Descriptions
                                    bordered
                                    title="My Account"
                                    size="small"
                                    >
                                    <Descriptions.Item label="Dealer ID">{displayFields.dealerId}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer Name">{displayFields.dealerName}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer Email">{displayFields.dEmail}</Descriptions.Item>
                                    <Descriptions.Item label="Name">{displayFields.contactName1} {displayFields.contactName2}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer Address1">{displayFields.dealerAddress}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer Address2">{displayFields.dealerAddress2}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer City">{displayFields.dealerCity}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer State">{displayFields.dealerState}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer Zip">{displayFields.dealerZip}</Descriptions.Item>
                                    <Descriptions.Item label="Dealer Phone">{displayFields.dealerPhone}</Descriptions.Item>
                                    <Descriptions.Item label="Routing Number">{displayFields.routingNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Account Number">{displayFields.accountNumber}</Descriptions.Item>
                                    <Descriptions.Item label="ACH Validation">{displayFields.achValidation}</Descriptions.Item>
                                    <Descriptions.Item label="ACH Limit">{displayFields.achLimit}</Descriptions.Item>
                                    <Descriptions.Item label="Account Type">{displayFields.accountType}</Descriptions.Item>
                                </Descriptions>

                            </Col>
                        </Row>
                        
                    </Container>
                    
                    <br />
                </LoadingOverlay>
            <Footer></Footer>

        </Fragment>
      );
}

export default MyAccountDisplay;