import React, { useState, useCallback, useEffect, Fragment } from "react";

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import 'bootstrap/dist/css/bootstrap.css';
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Form, Input, Button, Select, Radio } from 'antd';
import { Alert } from 'reactstrap';

import LoadingOverlay from 'react-loading-overlay'
import NavWithRouter from '../MyAccount/NavigationBar';
import Jumbotron from '../MyAccount/Jumbotron';
import Footer from '../Footer/Footer';

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const { Option } = Select;
const Styles = styled.div`
.bg-SunPowerBlue {
	background-color: #0076be;
}
.payment-radio {
    margin-left: 150px;
}
.form-check-label {
    text-align: center important!;
}
.ant-radio-button {
    padding: 10px;
}
.OrangeBtn {
    margin-left: 400px;
	font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn:hover {
    
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2 {
    font-weight: 500;
	background-color: #F18B21;
	color: hsla(0,0%,100%,1.00);
}
.OrangeBtn2:hover {
	background-color: #E2620E;
	color: hsla(0,0%,100%,1.00);
}
.BlueBtn {
	font-weight: 500;
	background-color: #0076BE;
	color: #FFFFFF;
}

.BlueBtn:hover {
	background-color:#39607A;
	color: #FFFFFF;
}

.BlueBtn:active {
	background-color:#39607A;
	color: #FFFFFF;
}
.BlueBtn:focus {
	background-color:#39607A;
	color: #FFFFFF;
}


.BlueBtnOutline {
	font-weight: 500;
	border: 1px solid #0076BE;
    background-color:#FFFFFF !important;
    color: #0076BE;
}

.BlueBtnOutline:hover {
	background-color:#0076BE !important;
	color: #FFFFFF;
}

.BlueBtnOutline:focus {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueBtnOutline:active {
	background-color:#39607A !important;
	color: #FFFFFF;
}

.BlueRadioBtn:hover {
	border: 1px solid #0076BE;
	color: #0076BE;
}

.BlueRadioBtn.active {
	border: 1px solid #0076BE;
	color: #0076BE;
}

main {
	min-height: 100vh;
	padding-bottom: 90px;
}

footer{
	height: 80px;
	margin-top: -80px;
	width: 100%;
}
.errorMsg {
    color: red;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.divider-light {
	background-color: #E0E0E5;
	height: 2px;
	margin-bottom: 14px;
}

.divider-orange {
	background-color: #F18B21;
	height: 3.5px;
	width: 36px;
}

.divider-orange-lg {
	background-color: #F18B21;
	height: 3px;
	width: 100px;
}


.bg-banner1 {
	background-image: url("images/1200x627_banner no logo (1).png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
}

.bg-SunPowerBlack {
	background-color: hsla(0,0%,0%,0.60);
	color:#FFFFFF;
	text-align: center;
}



/*Nav*/
.nav-link.active {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}

.nav-link:hover {
	color: #F18B21;
	border-bottom: #F18B21 2px solid;
}
/*Nav*/

/*Tabs*/

.nav-link.tab-title {
	color: #858585;
	background-color: #EEEEEE;
}

.nav-link.tab-title:hover {
	color: #E2620E;
}

.nav-link.tab-title.active {
	color: #E2620E;
}

/*Tabs*/


/*Links*/

.OrangeLink {
	color: #F18B21;
}
.OrangeLink:hover {
	color: #E2620E;
}

.BlueLink {
	color: #0076BE;
}

/*Typography*/

h1, h2, h3, h4, h5 {
	font-weight: 500;
}
`;

const mutation = gql`
    mutation ContactUs($subject: String, $name: String, $email: String, $message: String, $dealerName: String, $routingNumber: String, $accountNumber: String, $accountType: String) {
        contactUs(subject:$subject, name: $name, email:$email, message:$message, dealerName:$dealerName, routingNumber:$routingNumber, accountNumber:$accountNumber, accountType:$accountType)
}
`;
const { TextArea } = Input;
const ContactUs = (props) => {
    const [form] = Form.useForm();
    const [showSpinner, setShowSpinner] = useState({display: false})
    const [showSuccessMessage, setshowSuccessMessage] = useState(false);
    const [showACHFields, setshowACHFields] = useState(false);
    const [insertItem] = useMutation(mutation);
    const onFinish = values => {
        //console.log('Success:', values);
        setShowSpinner({display: true});
        insertItem({
            variables: {
                subject: values.subject, 
                name: values.fullname, 
                email: values.email, 
                message: values.message, 
                dealerName: values.dealerName,
                routingNumber: (values.routing) ? values.routing : '',
                accountNumber: (values.accountno) ? values.accountno : '',
                accountType: (values.accountType) ? values.accountType : ''
            }
            }).then(
            res => {
                if (res) {
                    //console.log('res:',res);
                    form.resetFields();
                    setshowSuccessMessage(true);
                    setShowSpinner({display: false});
                    setTimeout(() => {
                        
                        setshowSuccessMessage(false);
                      }, 3000);
                }
            },
            err => {console.log(err); }
            );
        
    };

    const captureSubject = (value) => {
        
        if (value === 'Change Bank/Routing Information') {
            setshowACHFields(true);
        } else {
            setshowACHFields(false);
        }
    }

    return (
        <Fragment>
            <LoadingOverlay
                active={showSpinner.display}
                spinner
                text='Submitting your contact request...'
                >
                <NavWithRouter />
                <Jumbotron image="cusservice" text="Customer Service"/>
                <Styles>
                    <main>
                        <div className="container">
                            <br></br>
                        </div>
                        <div className="container">
	                        <div className="row justify-content-center">
                                <div className="col">
                                <Alert color="success" style={{ display: ((showSuccessMessage) ? 'block' : 'none') }}>
                                    Your request has been logged on our end and we will reach out to you.
                                </Alert>
                                <Form
                                    form={form}
                                    {...layout}
                                    onFinish={onFinish}
                                    >
                                    <Form.Item
                                        name="subject"
                                        rules={[{ required: true, message: 'Please select a Subject' }]}
                                    >
                                        <Select placeholder="Subject" onChange={(e) => captureSubject(e)}>
                                            <Option value='SunPower Payment Inquiry'>SunPower Payment Inquiry</Option>
                                            <Option value='My Payment Status'>My Payment Status</Option>
                                            <Option value='My Visa Payment Reward Card'>My Visa Payment Reward Card</Option>
                                            <Option value='My SunPower Check'>My SunPower Check</Option>
                                            <Option value='Change Point Of Contact'>Change Point Of Contact</Option>
                                            <Option value='Change Bank/Routing Information'>Change Bank/ Routing Information</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="fullname"
                                        rules={[{ required: true, message: 'Please enter your full name' }]}
                                    >
                                        <Input placeholder='Name' />
                                    </Form.Item>
                                    <Form.Item
                                        name="dealerName"
                                        rules={[{ required: true, message: 'Please enter your Dealership Name' }]}
                                    >
                                        <Input placeholder='Dealership Name' />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Please enter your email' , type: 'email'}]}
                                        type= "email"
                                        
                                    >
                                        <Input placeholder='Email' />
                                    </Form.Item>
                                    <Form.Item
                                        name="message"
                                        rules={[{ required: true, message: 'Please enter some Message' }]}
                                    >
                                        <TextArea placeholder="Message" rows={5} />
                                    </Form.Item>
                                    {showACHFields ? 
                                        <div>
                                        <Form.Item
                                            name="routing"
                                            rules={[
                                            {
                                                required: showACHFields,
                                                message: 'Please input your ACH Routing Number',
                                            },
                                            {
                                                pattern: /[\d]{9}$/,
                                                message: "Routing number should be 9 digits",
                                            }
                                            ]}
                                        >
                                            <Input placeholder="ACH Routing Number" maxLength="9" />
                                        </Form.Item>
                                        <Form.Item
                                            name="accountno"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Account Number',
                                            },
                                            {
                                                pattern: /^[\d]{1,17}$/,
                                                message: "Account number cannot exceed 17 digits",
                                            }
                                            ]}
                                        >
                                            <Input placeholder="Account Number" />
                                        </Form.Item>
                                        <Form.Item name="accountType"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please select an account type!',
                                            }]}
                                        >
                                            <Radio.Group>
                                            <Radio value="checking">Checkings Account</Radio>
                                            <Radio value="saving">Savings Account</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        </div>
                                        : null}

                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                        Submit
                                        </Button>
                                    </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </main>
                </Styles>
            </LoadingOverlay>
            <Footer></Footer>
        </Fragment>
        
    );
}

export default ContactUs;