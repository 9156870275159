import React, { useState, useCallback, useEffect, Fragment } from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="disclosure">
			<p>&copy;{(new Date().getFullYear())} SunPower Corporation. All Rights Reserved.</p>
            <ul>
                    <li><a href="https://us.sunpower.com/privacy/website-policy" target="_blank"><font color="grey">PRIVACY POLICY</font></a></li>
                    <li><a href="https://global.sunpower.com/sunpower-terms-use-agreement" target="_blank"><font color="grey">TERMS</font></a></li>
                </ul>
	</footer>
    );
}

export default Footer;