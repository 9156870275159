import React, { useState, useCallback, useEffect, Fragment } from "react";

const CheckBoxGrid = ({checkBoxList, onCheckBoxChange, colNames, showSelectAll}) => {
    const [checkedItems, setCheckedItems] = useState(colNames);

    const stateChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked
          });
        onCheckBoxChange({
            ...checkedItems,
            [event.target.name]: event.target.checked
          });
    }

    const selectAll = () => {
        const checkState = {};
        const keys = Object.keys(colNames)
        keys.map(keyValue => {
            checkState[keyValue] = true;
        })
        setCheckedItems(checkState);
        onCheckBoxChange(checkState);
    }

    const unselectall = () => {
        const checkState = {};
        const keys = Object.keys(colNames)
        keys.map(keyValue => {
            checkState[keyValue] = false;
        })
        setCheckedItems(checkState);
        onCheckBoxChange(checkState);
    }

    return (
        <>
        {
            showSelectAll ?
            <div className="row">
                <div className="col">
                    <button className="btn btn-sm btn-light" type="button" id="selectall" onClick={selectAll}>Select All</button>
                    <button className="btn btn-sm btn-light" type="button" id="deselectall" onClick={unselectall}>Deselect All</button>
                    <br/>
                </div>
            </div>
             : null
        }
        
        <div className="row">
        {
            checkBoxList.map((columnList, index1) => {
                //console.log('index1: ',index1);
                return (
                    <div key={index1} className="col-lg">
                        {
                            columnList.map((checkList, index2) => {
                                //console.log('index2: ',index2);
                                //console.log(colNames[index1][index2]);
                                return (
                                    <div id={checkList.id} key={checkList.id} className="form-check">
                                        <input className="form-check-input" type="checkbox" value={checkList.id} checked={checkedItems[checkList.id]} name={checkList.id} onClick={(e)=> stateChange(e)}/>
                                        <label className="form-check-label" htmlFor={checkList.id}>
                                            {checkList.label}
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            })
        }
        </div>
        </>
    );
}

export default CheckBoxGrid;