import React, { useState, useCallback, useEffect, Fragment } from "react";
import gql from "graphql-tag";
import { useQuery, useSubscription, useMutation } from "@apollo/react-hooks";
import styled from 'styled-components';
import NavWithRouter from './NavigationBar';
import Jumbotron from './Jumbotron';
import { Container, Row, Col } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { AgGridColumn, AgGridReact } from "@ag-grid-community/react";
import {AllCommunityModules} from "@ag-grid-community/all-modules";
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Footer from '../Footer/Footer';
import { Form, Input, Button, Alert } from 'antd';
import 'antd/dist/antd.css';

const Styles = styled.div`
    .ag-cell {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    label {
        font-weight: normal !important;
    }

    .div-percent-bar {
        display: inline-block;
        height: 20px;
        position: relative;
    }

    .div-percent-value {
        position: absolute;
        padding-left: 4px;
        font-weight: bold;
        font-size: 13px;
    }

    .div-outer-div {
        display: inline-block;
        height: 100%;
        width: 100%;
    }
`;

const query = gql`
  query retrieveDealerPaymentInformation($startDate: String, $endDate: String, $dealerToken: String) {
    retrieveDealerPaymentInformation(startDate: $startDate, endDate: $endDate, dealerToken: $dealerToken) {
        reportDate
        dealerId
        dealerName
        infoLeaseContractNo
        sfdcId
        cusName
        sysAddress
        contractStatus
        counterSignDate
        fundingSubmissionDate
        depositDate
        contractPrice
        financingFees
        disbursementAmt
        pendingReason
        disposalFlag
    }
  }
`;

const insertMutation = gql`
    mutation ValidateACH($dealerToken: String) {
        validateACH(dealerToken: $dealerToken) 
}
`;

const DealerPaymentTable = (props) => {
    let pageSize = 10;
    let icons = {
            columnRemoveFromGroup: '<i class="fa fa-times"/>',
            filter: '<i class="fa fa-filter"/>',
            sortAscending: '<i class="fa fa-long-arrow-alt-down"/>',
            sortDescending: '<i class="fa fa-long-arrow-alt-up"/>',
            groupExpanded: '<i class="far fa-minus-square"/>',
            groupContracted: '<i class="far fa-plus-square"/>'
        };
    const [api, setApi] = useState(null);
    const [rowCount, setRowCount] = useState(null);
    const [showValidation, setShowValidation] = useState(false);
    const [spinnerDisplay, setSpinnerDisplay] = useState(true);
    const [spinnerDisplay2, setSpinnerDisplay2] = useState(false);
    const [spinnerText, setSpinnerText] = useState('Loading your content...');
    const [rowData, setRowData] = useState([]);
    const [quickFilterText, setquickFilterText] = useState(null);
    const dealerToken = (localStorage.getItem('dealerToken')) ? localStorage.getItem('dealerToken') : '';
    let {loading, data, error} = useQuery(query, {
        variables: { startDate: '', endDate: '', dealerToken },
        fetchPolicy: "network-first"
      });
    const [validateMutation] = useMutation(insertMutation);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("The test ACH amount entered is correct. You have successfully completed setting up your account for ACH transfers. Your payment statuses should be reflected in a few seconds.");
    
    useEffect(() => {
        //if (loading) console.log(loading);
        
        if (!loading && !error && spinnerDisplay) {
            setShowValidation((localStorage.getItem('validationAmount') > 0) ? true : false);
            //console.log('data: ',data);

            let tempRowData = [];
            data.retrieveDealerPaymentInformation.map(dealerEntry => {
                let dealer = {reportDate:dealerEntry.reportDate, dealerId:dealerEntry.dealerId, dealerName: dealerEntry.dealerName, infoLeaseContractNo: dealerEntry.infoLeaseContractNo,
                sfdcId: dealerEntry.sfdcId, cusName: dealerEntry.cusName, sysAddress: dealerEntry.sysAddress, contractStatus: dealerEntry.contractStatus,
                counterSignDate: dealerEntry.counterSignDate, fundingSubmissionDate: dealerEntry.fundingSubmissionDate, depositDate: dealerEntry.depositDate, contractPrice: dealerEntry.contractPrice,
                financingFees: dealerEntry.financingFees, disbursementAmt: dealerEntry.disbursementAmt, pendingReason: dealerEntry.pendingReason,
                disposalFlag: dealerEntry.disposalFlag
            }
                
                tempRowData.push(dealer);
            });
            setRowData(tempRowData);
            setSpinnerDisplay(false);
            
        }
    })

    const onGridReady = (params) => {
        setApi(params.api);
        calculateRowCount();
    };

    const onCellClicked = (event) => {
        //console.log(event);
        //console.log('onCellClicked: ' + event.data.name + ', row ' + event.rowIndex+', mobile:'+event.data.mobile);
    };

    const onRowSelected = (event) => {
        //console.log('onRowSelected: ' + event.node.data.name);
    };

    const onQuickFilterText = (event) => {
        //this.setState({ quickFilterText: event.target.value });
        setquickFilterText(event.target.value);
    };

    const onRefreshData = () => {
        
    };

    const calculateRowCount = () => {
        if (rowData) {
            if (api) {
                const model = api.getModel();
                const totalRows = rowData.length;
                const processedRows = model.getRowCount();
                setRowCount(processedRows.toLocaleString() + ' / ' + totalRows.toLocaleString());
            } else {
                setRowCount(0);
            }   
        }
    };

    const onFinish = (values) => {
        //console.log('Received values from form: ', values);
        setShowSuccessMessage(false);
        setSpinnerText('Approving your Test ACH');
        setSpinnerDisplay2(true);
        validateMutation({
            variables: {
                dealerToken: localStorage.getItem('dealerToken')
            }
            }).then(
            res => {
                localStorage.setItem('validationAmount', 0);
                //console.log(res);
                setSpinnerDisplay2(false);
                setShowValidation(false);
                setShowSuccessMessage(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                /*if (res.data.validateACH.status) {
                    window.location='dealerregisterconfirm?Message='+res.data.dealerACHRegister.Message;
                }*/
            },
            err => {console.log(err); }
            );
    };

    const checkPrice = (_, value) => {
        
        const validationAmount = localStorage.getItem('validationAmount');
        
        if (!value) {
            return Promise.reject(new Error('You need to enter the Test ACH amount!'));
        }
        if (parseFloat(value) === parseFloat(validationAmount)) {
          return Promise.resolve();
        }
    
        return Promise.reject(new Error('The amount you entered is not equal to the Test ACH amount!'));
    };


    return (
        <Fragment>
            <LoadingOverlay
                active={spinnerDisplay || spinnerDisplay2}
                spinner
                text={spinnerText}
                >
                <NavWithRouter />
                <Jumbotron image="reporting" text="Dealer Payment List"/>
                    <br />
                    <Container>
                        <Row>
                            <Col md={12}>
                            <p><strong>SunPower Dealer Payment Monitor</strong></p>
                            { showSuccessMessage ? <Alert message={successMessage} type="success" closable /> : null}
                            { showValidation ? 
                            <div>
                                <p><font color="red"><strong>Action Required</strong></font></p>
                                <p>
                                    <Form
                                        name="ach-validation"
                                        layout="inline"
                                        onFinish={onFinish}
                                        >
                                        <Form.Item name="price" label="Verify test ACH amount" rules={[{ validator: checkPrice }]} validateTrigger="onBlur">
                                            <Input placeholder="Enter Test ACH amount" />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                            Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </p> 
                            </div> : null }
                            <p>ACH credits are processed same day ACH credit provided funds are received from SunPower Corporate prior to 12:00 PT.</p>  

                            <p>Payment status updates are received regularly and are reflected below for your viewing.</p>  
                            <p>There are five statuses which you will be able to view and create reports within this portal: Submitted for Funding, Funding Hold, Funded, Disbursement, and Disbursement Hold.
                                For more information on status meaning, see the <a href="/faqs">Frequently Asked Questions.</a>
                            </p>

                            </Col>
                        </Row>
                        <Row>
                        <Col md={12}>
                            <Styles>
                                <div style={{ display: "inline-block", width: "100%", marginTop: 10, marginBottom: 10 }}>
                                    <div style={{ float: "left" }}>
                                    </div>
                                    <div style={{ float: "right", marginLeft: 20 }}>
                                        <label htmlFor="quickFilter">Quick Filter:&nbsp;</label>
                                        <input type="text" id="quickFilter" onChange={onQuickFilterText} placeholder="Type text to filter..." />
                                    </div>
                                </div>
                                <div style={{ height: 400, width: 1100 }} className="ag-theme-balham">
                                    <AgGridReact
                                        // listening for events
                                        onGridReady={onGridReady}
                                        onRowSelected={onRowSelected}
                                        onCellClicked={onCellClicked}
                                        onModelUpdated={calculateRowCount}
                                        pagination={true}
                                        paginationPageSize={pageSize}
                                        quickFilterText={quickFilterText}
                                        enableCellTextSelection={true}
                                        // binding to an object property
                                        icons={icons}

                                        // binding to array properties
                                        rowData={rowData}

                                        // register all modules (row model, csv/excel, row grouping etc)
                                        modules={AllCommunityModules}

                                        // no binding, just providing hard coded strings for the properties
                                        // boolean properties will default to true if provided (ie suppressRowClickSelection => suppressRowClickSelection="true")
                                        suppressRowClickSelection
                                        rowSelection="multiple"
                                        groupHeaders

                                        // setting grid wide date component
                                        //dateComponentFramework={DateComponent}

                                        // setting default column properties
                                        defaultColDef={{
                                            resizable: true,
                                            sortable: true,
                                            filter: true,
                                            //headerComponentFramework: SortableHeaderComponent,
                                            headerComponentParams: {
                                                menuIcon: 'fa-bars'
                                            }
                                        }}>
                                        <AgGridColumn headerName="Dealer Payment Information">
                                            <AgGridColumn headerName="Report Date" field="reportDate" width={110} filter="text" />
                                            <AgGridColumn headerName="Dealer ID" field="dealerId" width={120} filter="text" />
                                            <AgGridColumn headerName="Dealer Name" field="dealerName" width={130} filter="text" />
                                            <AgGridColumn headerName="Info Lease Contract No" field="infoLeaseContractNo" width={150} filter="text" />
                                            <AgGridColumn headerName="SFDC ID" field="sfdcId" width={120} filter="text" />
                                            <AgGridColumn headerName="Customer Name" field="cusName" width={120} filter="text" />
                                            <AgGridColumn headerName="System Address" field="sysAddress" width={200} filter="text" />
                                            <AgGridColumn headerName="Contract Status" field="contractStatus" width={120} filter="text" />
                                            <AgGridColumn headerName="Countersign Date" field="counterSignDate" width={80} filter="text" />
                                            <AgGridColumn headerName="Funding Submission Date" field="fundingSubmissionDate" width={80} filter="text" />
                                            <AgGridColumn headerName="Deposit Date" field="depositDate" width={80} filter="text" />
                                            <AgGridColumn headerName="Contract Price" field="contractPrice" width={80} filter="text" />
                                            <AgGridColumn headerName="SunPower Financing Fees (9%)" field="financingFees" width={80} filter="text" />
                                            <AgGridColumn headerName="Disbursement Amount" field="disbursementAmt" width={80} filter="text" />
                                            <AgGridColumn headerName="Pending Reason/Notes" field="pendingReason" width={80} filter="text" />
                                            <AgGridColumn headerName="Disposal Flag" field="disposalFlag" width={80} filter="text" />
                                        </AgGridColumn>
                                        
                                    </AgGridReact>
                                </div>
                            </Styles>
                        </Col>
                        </Row>
                        
                    </Container>
                    
                    <br />
                </LoadingOverlay>
            <Footer></Footer>

        </Fragment>
      );
}

export default DealerPaymentTable;