import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { withRouter } from "react-router";
import { history } from '../../_helpers';
import { authenticationService } from '../../_services';

const Styles = styled.div`
  .navbar {
        background-color: #fff;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #6c757d;

        &:hover {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid;
        }
        &:active {
            color: hsla(0,0%,0%,1.00);
	        border-bottom: #F18B21 2px solid !important;
        }
    }

    .nav-link.tab-title {
            color: #858585;
            background-color: #EEEEEE;
        }

        .nav-link.tab-title:hover {
            color: #E2620E;
        }

        .nav-link.tab-title.active {
            color: #E2620E;
        }
    .nav-link.active {
        border-bottom: #F18B21 2px solid !important;
    }
    
`;

const NavigationBar = props => {
    const { location } = props;
    const [showACHReg, setShowACHReg] = useState((localStorage.getItem('currentUser') ? false : true));
    //console.log(props.location);
    /*const bpsAdminMenu = () => {
        if (localStorage.getItem('bpsadminFlag') === 'true') {
            return (
                <NavDropdown title="BPS Admin" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1" href='/resendemail'>Resend Email</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/checkreport'>Check Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/physicalreport'>Physical Card Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/virtualreport'>Virtual Card Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/fundReport'>Fund Report</NavDropdown.Item> 
                    <NavDropdown.Item eventKey="4.1" href='/editPayment'>Edit Payment</NavDropdown.Item> 
                </NavDropdown>
            );
        } 
    }*/

    const logOutUser = () => {
        authenticationService.logout();
        history.push('/login');
    }

    return (
        <Styles>
            <Navbar expand="lg">
            <Navbar.Brand href="/"><img src="images/SunPower.png" width="180px" alt="logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav activeKey={location.pathname} className="justify-content-center mx-auto">
                    <Nav.Item>
                        <Nav.Link href="/">Payment List</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/report">Reporting</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/myaccount">MyAccount</Nav.Link>
                    </Nav.Item>
                    { showACHReg ? <Nav.Item><Nav.Link href="/register">ACH Credit Registration</Nav.Link></Nav.Item> : ''}
                    
                    <Nav.Item>
                        <Nav.Link href="/contactus">Contact Us</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/faqs">FAQs</Nav.Link>
                    </Nav.Item>
                    { !showACHReg ? <Nav.Item><Nav.Link onClick={logOutUser}>Logout</Nav.Link></Nav.Item> : ''}
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </Styles >
    );
};

const NavWithRouter = withRouter(NavigationBar);
export default NavWithRouter;
