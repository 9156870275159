import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Row, Col, Divider, Input, Form, Button, Radio } from 'antd';
import NavWithRouter from '../MyAccount/NavigationBar';
import Jumbotron from '../MyAccount/Jumbotron';
import './Register.scss';
import { Container } from 'react-bootstrap';
import 'antd/dist/antd.css';
import {useQuery, useMutation} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import queryString from 'query-string';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../Footer/Footer';
import axios from 'axios';

const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_NODE_URL+':4000/graphql',
});

const validateUsername = (username) => `
  query {
    validateUsername(username: "${username}") 
  }
`

const query = gql`
  query RegisterRequest($token: String!){
    registerRequest(token: $token) {
        dealerId
        dealerName
        dEmail
        contactName1
        contactName2
        dealerAddress
        dealerAddress2
        dealerPhone
        dealerCity
        dealerState
        dealerZip
        status
  }
  }
`;

const insertMutation = gql`
    mutation DealerACHRegister($dealerName: String, $dEmail: String, $contactName1: String, $contactName2: String, $dealerAddress:String, $dealerAddress2:String, $dealerPhone:String,$dealerCity:String, $dealerState: String, $dealerZip: String, $token: String, $routingNumber: String, $accountNumber: String, $achLimitReqd: String, $achLimit: String, $username: String, $password: String, $accountType: String) {
        dealerACHRegister(input: {dealerName: $dealerName, dEmail: $dEmail, contactName1: $contactName1, contactName2: $contactName2, dealerAddress:$dealerAddress, dealerAddress2:$dealerAddress2, dealerPhone:$dealerPhone,dealerCity:$dealerCity, dealerState:$dealerState, dealerZip: $dealerZip, token: $token, routingNumber: $routingNumber, accountNumber: $accountNumber, achLimitReqd: $achLimitReqd, achLimit: $achLimit, username: $username, password: $password, accountType: $accountType}) {
            status
            Message
        }
}
`;

const Register = (props) => {
    const values = queryString.parse(props.location.search);
    //console.log(values);
    let {loading, data, error} = useQuery(query, {
        variables : 
        {token: values.token}
    });
    const token = values.token;
    const [form] = Form.useForm();
    const [achLimitNeeded, setAchLimitNeeded] = useState(false);
    const [showSpinner1Text, setShowSpinner1Text] = useState('Loading your content...');
    const [showSpinner2Text, setShowSpinner2Text] = useState('');
    const [contactName1, setContactName1] = useState('');
    
    const [dealerFields, setDealerFields] = useState(
        { dealerId: '', dealerName: '', dEmail: '', contactName1: '', contactName2: '', dealerAddress: '', dealerAddress2: '', dealerPhone: '', dealerCity: '', dealerState: '', dealerZip: '', status: '',  __typename: ''}
    );
    
    const [showSpinner, setShowSpinner] = useState(true);
    const [showSpinner2, setShowSpinner2] = useState(false);
    useEffect(() => {
        if (loading) console.log(loading);
        
        if (!loading && !error && showSpinner && data.registerRequest.status) {
            console.log('data.registerRequest: ',data.registerRequest.contactName1);
            
            setDealerFields(data.registerRequest);
            setContactName1(data.registerRequest.contactName1);
            
            setShowSpinner(false);
            form.setFieldsValue({
                dname: data.registerRequest.dealerName,
                fname: data.registerRequest.contactName1,
                lname: data.registerRequest.contactName2,
                dphone: data.registerRequest.dealerPhone,
                daddress1: data.registerRequest.dealerAddress,
                daddress2: data.registerRequest.dealerAddress2,
                dcity: data.registerRequest.dealerCity,
                dstate: data.registerRequest.dealerState,
                dzip: data.registerRequest.dealerZip,
                email: data.registerRequest.dEmail,
                phone: data.registerRequest.dealerPhone
            });
            
        } else if (!loading) {
            if (data && !data.registerRequest.status) {
                window.location = '/dealerregistered?Message='+data.registerRequest.Message;
            }
        }
        /*const timeout = setTimeout(() =>{}, 0)

        return clearTimeout(timeout)*/
    });
    /*useEffect(() => {
        console.log("dealerFields: ", dealerFields);
      }, [dealerFields]);*/
    const [insertItem] = useMutation(insertMutation);
    const callMutation = (values) => {
        console.log('callmutation invoked');
        setShowSpinner2Text('Processing your registration request. Please wait...');
        setShowSpinner2(true);
        insertItem({
            variables: {
                dealerName: values.dname,
                dEmail: values.email,
                contactName1: values.fname,
                contactName2: values.lname,
                dealerAddress: values.daddress1,
                dealerAddress2: values.daddress2,
                dealerPhone: values.dphone,
                dealerCity: values.dcity,
                dealerState: values.dstate,
                dealerZip: values.dzip,
                token: token,
                routingNumber: values.routing,
                accountNumber: values.accountno,
                achLimitReqd: values.achlimitreqd,
                achLimit: values.achlimit,
                username: values.username,
                password: values.password,
                accountType: values.accountType
            }
            }).then(
            res => {
                //console.log(res);
                if (res.data.dealerACHRegister.status) {
                    window.location='dealerregisterconfirm?Message='+res.data.dealerACHRegister.Message;
                }
            },
            err => {console.log(err); }
            );
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        callMutation(values);
    };

    const trackACHLimitReqd = (e) => {
        setAchLimitNeeded(e.target.value === 'yes');
        /*
            initialValues={{
                        dname: dealerFields.dealerName,
                        dphone: dealerFields.dealerPhone,
                        daddress1: dealerFields.dealerAddress,
                        daddress2: dealerFields.dealerAddress2,
                        dcity: dealerFields.dealerCity,
                        dstate: dealerFields.dealerState,
                        dzip: dealerFields.dealerZip,
                        fname: contactName1,
                        lname: dealerFields.contactName2,
                        email: dealerFields.dEmail,
                        phone: dealerFields.dealerPhone,
                    }}
        */
    }

    const checkUserName = async (_, value) => {
        
        if (!value) {
            return Promise.reject(new Error('You need to enter the UserName!'));
        }
        try {
           
             let res = await axiosGraphQL
             .post('', {
               query: validateUsername(value),
             });
             //console.log(res.data.data.validateUsername);
             if (res.data.data.validateUsername) {
                return Promise.reject(new Error('This Username is already in use.'));
             } else {
                return Promise.resolve();
             }
          } catch (err) {
            
            return Promise.reject(new Error('This Username is already in use.'));
          }
    
        //return Promise.reject(new Error('This Username is already in use.'));
    };

    return (
        <>
            <LoadingOverlay
                active={showSpinner || showSpinner2}
                spinner
                text={showSpinner1Text || showSpinner2Text}
                >
            <NavWithRouter />
            <Jumbotron image="register" text="ACH Credit Registration"/>
            <Container>
                <h2>ACH Credit Registration</h2>
                <p>
                    Once the information below is complete you will receive a notice via your primary contact's email, that your ACH Credit is 
                    activated. Allow up to two business days to receive this notice.
                </p>
                <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                <div className="register-box">
                    <Divider orientation="left">Dealership information</Divider>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="dname"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Dealer Name',
                            },
                            ]}
                        >
                            <Input placeholder={dealerFields.dealerName} value={dealerFields.dealerName} defaultValue={dealerFields.dealerName} disabled />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="dphone"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Dealer Phone',
                            },
                            ]}
                        >
                            <Input placeholder="Dealer Phone" disabled />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="daddress1"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Dealer Address1',
                            },
                            ]}
                        >
                            <Input placeholder="Dealer Address1" disabled />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="daddress2"
                        >
                            <Input placeholder="Dealer Address2" disabled />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="dcity"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Dealer City',
                            },
                            ]}
                        >
                            <Input placeholder="Dealer City" disabled />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="dstate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Dealer State',
                                },
                                ]}
                        >
                            <Input placeholder="Dealer State" disabled />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="dzip"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Dealer Zip',
                            },
                            ]}
                        >
                            <Input placeholder="Dealer Zip" disabled />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Dealership Primary Contact</Divider>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="fname"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your First Name',
                            },
                            ]}
                        >
                            <Input placeholder="First Name" disabled />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="lname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last Name',
                                },
                                ]}
                        >
                            <Input placeholder="Last Name" disabled />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="email"
                            rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your Email',
                            },
                            ]}
                        >
                            <Input placeholder="Email" disabled />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Phone',
                                },
                                ]}
                        >
                            <Input placeholder="Phone" disabled />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Banking Information</Divider>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="routing"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your ACH Routing Number',
                            },
                            {
                                pattern: /[\d]{9}$/,
                                message: "Routing number should be 9 digits",
                              }
                            ]}
                        >
                            <Input placeholder="ACH Routing Number" maxLength="9" />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="confirmrouting"
                            dependencies={['routing']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your ACH Routing Number!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('routing') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The two Routing Numbers that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input placeholder="Confirm ACH Routing Number" />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="accountno"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Account Number',
                            },
                            {
                                pattern: /^[\d]{1,17}$/,
                                message: "Account number cannot exceed 17 digits",
                              }
                            ]}
                        >
                            <Input placeholder="Account Number" />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="confirmaccountno"
                            dependencies={['accountno']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your Account Number!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('accountno') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The two Account Numbers that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input placeholder="Confirm Account Number" />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                            Do you have a Daily ACH Credit Limit?
                        </Col>
                        <Col span={10} offset={2}>
                            If yes, what is the Limit?
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item name="achlimitreqd">
                            <Radio.Group onChange={trackACHLimitReqd}>
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <Form.Item
                                name="achlimit"
                                dependencies={['achlimitreqd']}
                                hasFeedback
                                rules={[
                                    {
                                      required: achLimitNeeded,
                                      message: 'Please input your ACH Limit',
                                    },
                                  ]}
                            >
                                <Input />

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                            Is this a checkings account or a savings account?
                        </Col>
                        <Col span={10} offset={2}>
                            <Form.Item name="accountType"
                                hasFeedback
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select an account type!',
                                }]}
                            >
                                <Radio.Group onChange={trackACHLimitReqd}>
                                <Radio value="checking">Checkings Account</Radio>
                                <Radio value="saving">Savings Account</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Divider orientation="left">Login Information</Divider>
                    <Row>
                        <Col span={10} offset={1}>
                            <Form.Item
                                name="username"
                                rules={[{ validator: checkUserName }]}
                                validateTrigger="onBlur"
                            >
                                <Input placeholder="UserName" />

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} offset={1}>
                        <Form.Item
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Password',
                            },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                        <Form.Item
                            name="confirmpassword"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your Password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('The Password & Confirm Password that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} offset={10}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                            Submit
                            </Button>
                        </Form.Item>
                        </Col>
                    </Row>
                </div>
                </Form>
            </Container>
            </LoadingOverlay>
            <Footer></Footer>
        </>
    );
}

export default Register;