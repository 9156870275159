import React, { useState, useCallback, useEffect, Fragment } from 'react';
import NavWithRouter from '../MyAccount/NavigationBar';
import Jumbotron from '../MyAccount/Jumbotron';
import './Register.scss';
import { Container, Alert } from 'react-bootstrap';
import 'antd/dist/antd.css';
import queryString from 'query-string';
import Footer from '../Footer/Footer';

const RegisterError = (props) => {
    const values = queryString.parse(props.location.search);
    const errorMessage = (values.Message === 'Token not Found') ? 'The link you used is no longer valid' : 'You have already completed your registration';
    return (
        <>
            <NavWithRouter />
            <Jumbotron image="register" text="ACH Credit Registration"/>
            <Container>
            <Alert key="1" variant="danger">
                {errorMessage}
            </Alert>
            </Container>
            <Footer></Footer>
        </>
    );
}

export default RegisterError;