import React, { useState, useCallback, useEffect, Fragment } from 'react';
import NavWithRouter from '../MyAccount/NavigationBar';
import Jumbotron from '../MyAccount/Jumbotron';
import './Register.scss';
import { Container , Alert} from 'react-bootstrap';
import 'antd/dist/antd.css';
import queryString from 'query-string';
import Footer from '../Footer/Footer';

const RegisterConfirm = (props) => {
    return (
        <>
            <NavWithRouter />
            <Jumbotron image="register" text="ACH Credit Registration"/>
            <Container>
                <br></br>
                <Alert key="1" variant="success">
                    You have successfully confirmed your Dealer ACH Registration.
                </Alert>
                
            </Container>
            <Footer></Footer>
        </>
    );
}

export default RegisterConfirm;