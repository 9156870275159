import { BehaviorSubject } from 'rxjs';
import { useMutation } from "@apollo/react-hooks";
import { handleResponse } from '../_helpers';
import gql from 'graphql-tag';
import axios from 'axios';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

const axiosGraphQL = axios.create({
    baseURL: process.env.REACT_APP_NODE_URL+':4000/graphql',
  });

const LOGIN = gql`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      email
    }
  }
`;

const loginQuery = (email, password) => `
  mutation {
    loginDealer(input: {email: "${email}", password: "${password}"}) {
      token
      fname
      lname
      email
      title
      phone
      bpsadminFlag
      resetPwdFlag
      dealerToken
      validationAmount
    }
  }
`

function login(email, password) {
    /*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };*/
    //console.log(email,' : ',password);
    return new Promise((resolve,reject) => {
        try {
            axiosGraphQL
            .post('', {
              query: loginQuery(email, password),
            })
            .then(result => {
                //console.log(result);
                //console.log(result.data);
                if (result.data.errors) {
                  reject(result.data.errors[0].message);
                  return;
                } else {
                  //console.log(result.data.data.login.token);
                  //localStorage.setItem('currentUser', JSON.stringify(user));
                  localStorage.setItem('currentUser', result.data.data.loginDealer.token);
                  localStorage.setItem('fname', result.data.data.loginDealer.fname);
                  localStorage.setItem('lname', result.data.data.loginDealer.lname);
                  localStorage.setItem('email', result.data.data.loginDealer.email);
                  localStorage.setItem('title', result.data.data.loginDealer.title);
                  localStorage.setItem('phone', result.data.data.loginDealer.phone);
                  localStorage.setItem('bpsadminFlag', result.data.data.loginDealer.bpsadminFlag);
                  localStorage.setItem('resetPwdFlag', result.data.data.loginDealer.resetPwdFlag);
                  localStorage.setItem('dealerToken', result.data.data.loginDealer.dealerToken);
                  localStorage.setItem('validationAmount', result.data.data.loginDealer.validationAmount);
                  currentUserSubject.next(result.data.data.loginDealer.token);
                  resolve(result.data.data.loginDealer.token);
                }
                
                //return user;
            });
          } catch (err) {
            //console.log(err);
            //console.log('failed');
            reject(err);
          }
        /*return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
            .then(handleResponse)
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                currentUserSubject.next(user);
    
                return user;
            });*/
        
    })
    
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('fname');
    localStorage.removeItem('lname');
    localStorage.removeItem('email');
    localStorage.removeItem('title');
    localStorage.removeItem('phone');
    localStorage.removeItem('bpsadminFlag');
    localStorage.removeItem('resetPwdFlag');
    localStorage.removeItem('dealerToken');
    localStorage.removeItem('validationAmount');
    currentUserSubject.next(null);
}
